import audio from '../assets/audio/spoken-introduction-instructions.mp3'
import { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Tween } from 'react-gsap';


const transcript = [
    {
        "timestamp": "00:00:00.02",
        "content": "Adjust your volume until you can hear my voice\ncomfortably, as we move forward from here.",
    },
    {
        "timestamp": "00:00:08.00",
        "content": "at any moment in your experience,\nfeel free to adjust the volume to your comfort.",
    },
    {
        "timestamp": "00:00:16.13",
        "content": "Imagine I am standing closely\nin front of you.",
    },
    {
        "timestamp": "00:00:21.00",
        "content": "Follow the sounds\nas I rub my fingertips together,\naround your kinesphere.",
    },
    {
        "timestamp": "00:00:31.20",
        "content": " \n ",
    },
    {
        "timestamp": "00:00:52.06",
        "content": "星星 (Sing Sing)",
    },
    {
        "timestamp": "00:00:55.5",
        "content": "In Cantonese\n日 embodies sun,\n生 embodies birth,\nand\n星星 embodies the stars.",
    },
    {
        "timestamp": "00:01:11.0",
        "content": "This piece is about\nlistening, seeing and making\nconstellations with each other\nacross distances\nand where we are on this earth\nin relation to what we observe in the night sky.",
    },
    {
        "timestamp": "00:01:28.12",
        "content": "As a group,\nI invite us to explore together and witness\nthe ever changing constellations of\n movements, pathways and sounds.",
    },
    {
        "timestamp": "00:01:41.09",
        "content": "As we tune into our collective creative process.\nI invite a kind and receptive\n improvisational exchange\nas a way of opening ourselves\nto joy and to each other.",
    },
    {
        "timestamp": "00:01:56.03",
        "content": "We are about to enter\ninto a shared space.",
    },
    {
        "timestamp": "00:02:00.13",
        "content": "You may experience\nthe imprint left behind by previous visitors.",
    },
    {
        "timestamp": "00:02:07.13",
        "content": "You may also experience,\nan interplay between you and other visitors\npresent in this space.",
    },
    {
        "timestamp": "00:02:16.13",
        "content": "Please take care of yourself and each other.",
    },
    {
        "timestamp": "00:02:20.02",
        "content": "You are invited to play the stars in this shared listening space.",
    }
]

export default function Instruction({settings,nextStep}) {


    const audioRef = useRef(null);
    const [currentContent, setCurrentContent] = useState([]);
    const [contentPrev, setContentPrev] = useState([]);
    const [audioEnded, setAudioEnded] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [key, setKey] = useState(0);
    const tweenContentMain = useRef(null);
    const tweenContentPrev = useRef(null);

    const [cookies, setCookie] = useCookies(['returningVisitor']);

    const handleAudioEnded = () => {
        setCookie('returningVisitor',true, {maxAge:60*60*24*7});
        setAudioEnded(true);
    };

    const getTimeInSeconds = (timestamp) => {
        const timestampParts = timestamp.split(':').map(parseFloat);
        return timestampParts[0] * 3600 + timestampParts[1] * 60 + timestampParts[2];
    };

    const handleTimeUpdate = () => {
        const currentTime = audioRef.current.currentTime;
        const nextEntry = transcript[currentIndex];
        // console.log(currentTime,nextEntry.timestamp);
  
        if (nextEntry && currentTime >= getTimeInSeconds(nextEntry.timestamp)) {
            console.log('next',currentIndex);
            setContentPrev(currentContent);
            setCurrentContent(nextEntry.content.split('\n').filter(line => line.trim() !== ''));
            setCurrentIndex(currentIndex + 1);
            setKey(prevKey => prevKey + 1);

            // if ( (currentIndex + 1) >= transcript.length){
            //     handleAudioEnded();
            // }
        }
    };

    useEffect(()=>{
        if (tweenContentMain.current.getGSAP().progress()===1){
            tweenContentMain.current.createTween();
            tweenContentMain.current.getGSAP().play();
        }
        // tweenContentMain.current.getGSAP().invalidate();
        // tweenContentMain.current.getGSAP().restart();
    },[currentContent,tweenContentMain]);
    useEffect(()=>{
        if (tweenContentPrev.current.getGSAP().progress()===1){
            tweenContentPrev.current.createTween();
            tweenContentPrev.current.getGSAP().play();
        }
        // tweenContentPrev.current.getGSAP().invalidate();
        // tweenContentPrev.current.getGSAP().restart();
    },[contentPrev,tweenContentPrev]);

    useEffect(() => {
        console.log('cookie',cookies);

        const audioElement = audioRef.current;
        audioElement.addEventListener('timeupdate', handleTimeUpdate);
      
        return () => {
            audioElement.removeEventListener('timeupdate', handleTimeUpdate);
        };

    }, [audioRef,currentIndex]);
    
    return (
        <>
            <div className='flex flex-col justify-center gap-4 lg:gap-12 h-64 lg:h-96 w-full max-w-screen-md'>
                <audio id="instructionsAudio" ref={audioRef} autoPlay preload="auto" onEnded={handleAudioEnded}>
                    <source src={audio} type="audio/mp3" />
                </audio>

                <div id="transcript" className="h-56 lg:h-72 relative">
                    <Tween from={{ opacity: 1 }} to={{ opacity: 0 }} ref={tweenContentPrev} wrapper={<div className="prev-block" />}>
                        {contentPrev.map( (line, index) => 
                            <div key={`prev-${key}-${index}`} id={`prev-${key}-${index}`}>{ line }</div>
                        )}
                    </Tween>
                    <Tween from={{ opacity: 0 }} to={{ opacity: 1, stagger: .2 }} ref={tweenContentMain} wrapper={<div className="next-block" />}>
                        {currentContent.map((line, index) => (
                            <div key={`main-${key}-${index}`} id={`main-${key}-${index}`}>{ line }</div>
                        ))}
                    </Tween>
                </div>
               
                
                {audioEnded ? 
                    <Tween
                        from={{opacity: 0 }} >
                            <button className='btn' onClick={nextStep}>{settings.intro.cta}</button>
                        </Tween>
                    : cookies.returningVisitor && <Tween
                        from={{opacity: 0 }} >
                            <button className='underline font-thin text-sm absolute bottom-8 right-8' onClick={nextStep}>{settings.cta}</button>
                      </Tween>
                    }
            </div>
            
        </>
    )
}

