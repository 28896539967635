import headphones from '../assets/headphones.svg';
import audio from '../assets/audio/spoken-introduction-headphones.mp3';
import { useEffect, useRef, useState } from 'react';
import { Tween } from 'react-gsap';
import { openFullscreen } from './Model';

export default function Intro({ settings, nextStep }) {

    const audioRef = useRef();

    function handleClick(){
        openFullscreen();
        nextStep();
    }

    return (
        <>
            <div className='flex flex-col justify-center gap-4 lg:gap-12 h-64 lg:h-96 w-full max-w-screen-md'>
                <div className='flex gap-4 w-full h-56 lg:h-72 justify-center items-center'>
                    <img src={headphones} alt="headphones" />
                    <p>{settings.title}</p>
                </div>
                <Tween
                    to={{ opacity: 1, y: 0 }}
                    from={{ opacity: 0, y: 10 }}
                    delay={2}
                >
                    <button id="introNext" className="mx-auto underline" onClick={handleClick}>{settings.cta}</button>
                </Tween>
                <audio id="player" ref={audioRef} autoPlay preload="auto" ><source src={audio} type="audio/mp3" /></audio>
            </div>
        </>
    )
}

