import React from 'react';
import close from '../assets/close.svg'; // Import the first image
import phone from '../assets/phone.svg'; // Import the second image
import { handleBackClick } from './Model';

function MobileOverlay() {

    return (
        <div id="mobileOverlay" className='bg-dark absolute h-screen w-screen overflow-hidden left-0 top-0 z-50'>
            <div className='flex flex-col justify-center items-center align-center h-full'>
                <img src={phone} className="phone" alt="Rotate screen to landscape to view this experience" />
                <span className='mt-8'>This experience is best enjoyed on desktop.</span>
            </div>
            <button className="close absolute bottom-4 w-4 h-8 left-0 right-0 mx-auto" alt="Close and exit the experience" onClick={handleBackClick}>
                 <img src={close} />
            </button>
         
        </div>
    );
}

export default MobileOverlay;
