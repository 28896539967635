import React, { useEffect, useState, useRef } from "react";
import { Tween } from "react-gsap";

function Credits({ settings, nav, show }) {
  const [creditsShowing, setCreditsShowing] = useState(true);
  const creditRef = useRef();

  return (
    <>
      <div
        className={`fullscreen ${creditsShowing ? "showing" : ""}`}
        id="collective-credits"
        ref={creditRef}
      >
        <Tween
          from={{ opacity: 0 }}
          to={{ opacity: 1 }}
          playState={creditsShowing ? "play" : "stop"}
        >
          <div className="collective w-full text-left px-8 pt-12">
            <h1 className="py-16 w-fit mx-auto">
              <img className="mx-auto" src={settings.logo} />
            </h1>

            <div>
              <div>
                <p className="sm:mx-8 text-base mb-4">{settings.excerpt}</p>
                <p className="sm:mx-8 text-base mb-4">{settings.excerpt2}</p>
                <p className="sm:mx-8 text-base mb-4">{settings.excerpt3}</p>
              </div>
              <div className="flex flex-col justify-center lg:grid lg:grid-cols-3 gap-8 w-full mx-auto max-w-sm lg:max-w-full mt-16 lg:px-4">
                {settings.credits.map((credit, index) => {
                  return (
                    <div className="" key={index} data-index={index}>
                      
                      <div className="artist flex-flex-col">
                        <img src={credit.imgURL} />
                        <div>
                            <h2 className="text-lg">
                                {credit.projectTitle}
                            </h2>
                          <h2 className="mb-2 text-lg leading-tight">{credit.title}</h2>
                          <p className="leading-tight text-base mb-4">{credit.excerpt}</p>
                        </div>
                      </div>
                      <div className="border-b border-light w-40 sm:w-60 mx-auto mt-6 lg:hidden"></div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Tween>
      </div>
    </>
  );
}

export default Credits;
