import React, { useEffect, useRef, useState } from "react";
import Nav from './components/Nav';
import Starfield from "./components/Starfield";
import Loading from "./components/Loading";
import Intro from "./components/Intro";
import Instruction from "./components/Instruction";
import MainApp from "./components/MainApp";
import Credits from "./components/Credits";
import MobileOverlay from "./components/MobileOverlay";
import { io } from "socket.io-client";
import settings from './config.json';
import {isMobile} from 'react-device-detect';

import './App.css';

function App() {

  const LOADING = 0;
  const INTRO = 1;
  const INSTRUCTION = 2;
  const APP = 3;
  // const CREDITS = 4;

  const [ state, setState ] = useState( LOADING );
  const [ socket, setSocket ] = useState(null);
  const [ serverTime, setServerTime ] = useState(0); // used to track start time on server.
  const [ delta, setDelta ] = useState(0); // used to track difference between server time and local time
  const [ counter, setCounter ] = useState(0); // used to track user's colour
  // const starfieldRef = useRef(null);
  // const creditsRef = useRef(null);
  const [ deviceWidth, setDeviceWidth] = useState(window.innerWidth);
  const [ isPortrait, setIsPortrait] = useState(window.innerWidth < window.innerHeight);
  const [ showCredits, setShowCredits ] = useState(false);
  const [ dragStart, setDragStart ] = useState(-1);
  const [ draggedOpen, setDraggedOpen ] = useState(true);


  const toggleCredits = (event) => {
    setShowCredits(!showCredits);
  };

  function startApp(){
    // initialize io connection.
    const socket = io('https://backend-singsing.playreplay.ca');
    setSocket( socket );
    socket.on('initialize', (init) => {
        const now = (new Date()).getTime();
        console.log('init',init, now);
        setServerTime( init.serverTime );
        setDelta( init.serverTime - now );
        setCounter( init.counter );
        
    })
    setState(APP);
  }

  useEffect(() => {
    
    const handleResize = () => {
      setDeviceWidth(window.innerWidth);
      setIsPortrait(window.innerWidth < window.innerHeight);
    }

    window.addEventListener('resize', handleResize);

    const onPageLoad = () => {
      console.log('page is loaded');
      setState(INTRO);
    };

    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad);
    }
    console.log('useEffect');

  }, []);

  useEffect(() => {
    const nav = document.querySelector('nav');

    let timeout = setTimeout(hideNav, 5000);
    function showNav() {
      clearTimeout(timeout);
      nav.style.top = "0";
      timeout = setTimeout(hideNav, 5000);
    }
    function peekNav() {
      clearTimeout(timeout);
      nav.style.top = "-70px";
      timeout = setTimeout(hideNav, 5000);
    }
    function dragNav(yy) {
      clearTimeout(timeout);
      let n = -90 + (yy - dragStart);
      nav.style.top = Math.min(0,n)+"px";
      if (n>=0) {
        setDraggedOpen(true);
        handleTouchEnd();
      }
      timeout = setTimeout(hideNav, 5000);
    }
    function hideNav() {
      nav.style.top = "-90px";
      setDragStart(-1);
      setDraggedOpen(false);
    }
    function handleTouchMove(e){
      // console.log('touchmove',e.targetTouches[0].clientY);
      dragNav(e.targetTouches[0].clientY);
    }
    function handleTouchEnd(e){
      // console.log('touchend');
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    }
    if (isMobile) {
      document.addEventListener('touchstart', (event) => {
        // console.log('touch!',event);
        
        if (event.targetTouches[0].clientY<=100){
          if (draggedOpen){
            console.log('already open');
            showNav();
          } else {
            peekNav();
            document.addEventListener('touchmove', handleTouchMove);
            document.addEventListener('touchend', handleTouchEnd);
          }
        }
      });
    } else {
      document.addEventListener('mousemove', (event) => {
        if (event.clientY<=100) showNav();
      });
    }
    
      // only show nav if moving around top of screen.
      // console.log(isMobile);
      
      //   if (dragStart>0) {
      //     dragNav( event.clientY );
      //   } else {
      //     if (event.clientY<=100) {
      //       setDragStart( event.clientY );
      //       peekNav();
      //     }
      //   }
      // } else {
      //   if (event.clientY<=100) showNav();
      // }
      

});

  return (
    <div className="App">
      {((isPortrait) && (deviceWidth <= 768) )&& <MobileOverlay /> }
      <Nav showCredits={showCredits} toggleCredits={toggleCredits} />
      { serverTime && <div className="starfield-container"><Starfield serverTime={serverTime} /></div> }
      { state===LOADING ?
        <Loading />
      : state===INTRO ?
        <Intro nextStep={()=>setState(INSTRUCTION)} settings={settings.loading} />
      : state===INSTRUCTION ?
        <Instruction nextStep={startApp} settings={settings.instructions} />
      : state===APP ?
        <MainApp list={settings.stars} delta={delta} socket={socket} counter={counter} />
      : <></>  
      }
      {showCredits && (
        <Credits settings={settings.collective} show={showCredits} />
      )}
    </div>
     
  );
}

export default App;
