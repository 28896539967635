import { useState } from "react";
import {ReactComponent as CloseIcon} from '../assets/close.svg';
import {ReactComponent as FullScreenIcon} from '../assets/fullscreen.svg';
import {ReactComponent as FacebookIcon} from '../assets/facebook.svg';
import {ReactComponent as TwitterIcon} from '../assets/twitter.svg';
import { Tween } from "react-gsap";
import { openFullscreen, handleBackClick } from './Model';

export default function Nav({toggleCredits, showCredits}) {

    const [ showDefaultShare, setShowDefaultShare ] = useState(false);

    // const shareIcon1 = <path d="M16 1.99976L16 22.9998M16 1.99976L23 8.99988M16 1.99976L9 8.99988M25.5 7.49975L29 7.49975L29 28.4998L3 28.4998L3 7.49975L6.49999 7.49975" stroke="white" strokeWidth="1.25"  strokeOpacity="1" />;
    // const shareIcon2 = <path d="M15.5 0.999755L15.5 21.9998M15.5 0.999755L22.5 7.99988M15.5 0.999755L8.5 7.99988M25 11.4998L28.5 11.4998L28.5 32.4998L2.5 32.4998L2.5 11.4998L5.99999 11.4998" stroke="white" strokeWidth="1.25" strokeOpacity="1" />;
    const creditsIcon1 = <path id="Union" d="M23.8672 1.62305H35.8672V0.373047H23.8672V1.62305ZM0.888672 0.376953H0.263672V1.00195V26.002V26.627H0.888672H20.8887H21.5137V26.002V1.00195V0.376953H20.8887H0.888672ZM1.51367 25.377V1.62695H20.2637V25.377H1.51367ZM35.8672 6.62305H23.8672V5.37305H35.8672V6.62305ZM23.8672 11.623H29.8672V10.373H23.8672V11.623Z" fill="white"/>;
    const creditsIcon2 = <path d="M23.8672 1.62305H35.8672V0.373047H23.8672V1.62305ZM0.888672 0.376953H0.263672V1.00195V26.002V26.627H0.888672H20.8887H21.5137V26.002V1.00195V0.376953H20.8887H0.888672ZM1.51367 25.377V1.62695H20.2637V25.377H1.51367ZM5.94673 9.4439L10.0048 13.502L5.94673 17.56L6.83061 18.4439L10.8887 14.3858L14.9467 18.4439L15.8306 17.56L11.7726 13.502L15.8306 9.4439L14.9467 8.56001L10.8887 12.6181L6.83061 8.56001L5.94673 9.4439ZM35.8672 6.62305H23.8672V5.37305H35.8672V6.62305ZM23.8672 11.623H29.8672V10.373H23.8672V11.623Z" fill="white"/>;

    // const getIconSrc = ()=>{
    //     if(showDefaultShare===true) {
    //         return shareIcon2;
    //     } else if (showDefaultShare===false) {
    //         return shareIcon1;
    //     }
    // }

    const getCloseCreditsIconSrc = ()=>{
        
        if(showCredits===true) {
            return creditsIcon2;
        } else {
            return creditsIcon1;
        }
    }

    let tweenprops;
    if (showDefaultShare===false) tweenprops = {opacity:1};
    let tweencreditsprops;
    if (showCredits===false) tweenprops = {opacity:1};

    let iclass = 'shareIcon';
    if (showDefaultShare===true) iclass += ' up';

    let cclass = 'creditsCloseIcon';
    if (showCredits===true) cclass += ' in';
    
    function handleShareClick(event) {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            // const response = await fetch(event.target.getAttribute('data-share-image'));
            // const blob = await response.blob();
        
            // const image = new File([blob], 'image.jpg', { type: "image/jpeg" });
            
            function getCurrentURL() {
                return window.location.href;
            }
            const shareLink = getCurrentURL();

            const shareData = {
                    text: "Autolysis",
                    url: shareLink,
            }
            navigator.share(shareData);

        }
        else{
            setShowDefaultShare( !showDefaultShare );
        } 
    }

    return (
        <Tween
            from={{opacity:0}}
            to={{opacity:1}}
        >
            <nav className='flex w-full p-6 justify-between align-baseline absolute top-0 left-0 z-40'>
                <button id="collective" onClick={handleBackClick}>
                    <CloseIcon />
                </button>
                <div className="relative">
                    <button onClick={handleShareClick} id="share" className="share" data-share-title="Autolysis" data-share-url="https://google.com">
                    <Tween to={tweenprops}>
                        <svg className={iclass} width="31" height="31" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path id="up" d="M16 1.99976L16 22.9998M16 1.99976L23 8.99988M16 1.99976L9 8.99988" stroke="white" strokeWidth="1.25"  strokeOpacity="1" /><path d="M25.5 7.49975L29 7.49975L29 28.4998L3 28.4998L3 7.49975L6.49999 7.49975" stroke="white" strokeWidth="1.25"  strokeOpacity="1" />
                        </svg>
                    </Tween>
                    </button> 
                    {showDefaultShare && <div className="default-share">
                    <div className="share-wrap">
                        <a id="fb" rel="noopener noreferrer" target="_blank" className="social fb"
                        href="https://www.facebook.com/sharer/sharer.php?u=https://google.com"
                        data-size="large" data-share-title="Autolysis" data-share-url="https://google.com">
                            <Tween
                                from={{opacity:0, duration: 0.5, x:15}}
                                to={{opacity:1, duration: 0.5, x:0}}
                            >
                                <FacebookIcon />
                            </Tween>                              
                        </a>
                        <a id="tw" rel="noopener noreferrer" target="_blank" className="social tw"
                        href="https://twitter.com/intent/tweet?text=https://google.com"
                        data-size="large" data-share-title="Autolysis" data-share-url="https://google.com">
                             <Tween
                                from={{opacity:0, duration: 0.5, x:-15}}
                                to={{opacity:1, duration: 0.5, x:0}}
                            >
                                <TwitterIcon />
                            </Tween> 
                        </a>
                    </div>
                </div>}
                </div>
                
                <button id="credits" onClick={toggleCredits}>
                    
                    <Tween to={tweencreditsprops}>
                    <svg className={cclass} width="36" height="27" viewBox="0 0 36 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        {getCloseCreditsIconSrc()}
                    </svg>
                </Tween>
                </button>
                <button id="fullscreen-toggle" onClick={openFullscreen}>
                    <FullScreenIcon />
                </button>
            </nav>
        </Tween>
        
    );
}
