
import { useState, useRef, useEffect,  } from "react";
import { Tween, Timeline } from "react-gsap";
// import { gsap } from 'gsap';

// const starsimg0 = '/img/Stars Overlay 1.jpg';
const starsimg1 = '/img/Stars Overlay 2.jpg';
const starsimg2 = '/img/Stars Overlay 3.jpg';
const starsimg3 = '/img/Stars Overlay 4.jpg';
const starsimg4 = '/img/Stars Overlay 5.jpg';
const spaceimg0 = '/img/Space Backdrop 1A.jpg';
const spaceimg1 = '/img/Space Backdrop 2A.jpg';
const spaceimg2 = '/img/Space Backdrop 3A.jpg';
const spaceimg3 = '/img/Space Backdrop 4A.jpg';
const spaceimg4 = '/img/Space Backdrop 5A.jpg';
const spaceimg5 = '/img/Space Backdrop 6A.jpg';
const spaceimg6 = '/img/Space Backdrop 7A.jpg';
const spaceimg7 = '/img/Space Backdrop 8A.jpg';
const spaceimg8 = '/img/Space Backdrop 9A.jpg';
const spaceimg9 = '/img/Space Backdrop 10A.jpg';
const spaceimg10 = '/img/Space Backdrop 11A.jpg';
const spaceimg11 = '/img/Space Backdrop 12A.jpg';
const spaceimg12 = '/img/Space Backdrop 13A.jpg';
const spaceimg13 = '/img/Space Backdrop 14A.jpg';
const spaceimg14 = '/img/Space Backdrop 15A.jpg';
const spaceimg15 = '/img/Space Backdrop 16A.jpg';
const spaceimg16 = '/img/Space Backdrop 17A.jpg';
const spaceimg17 = '/img/Space Backdrop 18A.jpg';
const spaceimg18 = '/img/Space Backdrop 19A.jpg';


export default function Starfield({playState,serverTime}) {

    const seconds = 1; // parseFloat(window.location.search.substring(1)) || 2;
    const period = seconds * 60 * 1000; // 2 * 1seconds.

    const [counter, setSpaceState] = useState( Math.floor(serverTime/period) );
    const tweenSpaceMain = useRef(null);
    const tweenStarsMain = useRef(null);

    useEffect(() => {
        tweenSpaceMain.current.getGSAP().restart();
        tweenStarsMain.current.getGSAP().restart();

        let repeater = setTimeout(()=>setSpaceState(counter +1), period);
        return ()=> clearTimeout(repeater);
    }, [counter] ); // runs every time spaceState changes.


    const getStarSrc = (offset) => {
        const starState = (counter+offset) % 4;
        const id = 'star'+offset?'Main':'Prev';
        if (starState===0) {
            return <img src={starsimg1} className='space' id={id} alt="a final frontier" />;
        } else if (starState===1) {
            return <img src={starsimg2} className='space' id={id} alt="a final frontier" />;
        }
        else if (starState===2) {
            return <img src={starsimg3} className='space' id={id} alt="a final frontier" />;
        }
        else if (starState===3) {
            return <img src={starsimg4} className='space' id={id} alt="a final frontier" />;
        }
    }

    const getSpaceSrc = (offset) => {
        const spaceState = (counter+offset) % 19;
        const id = 'space'+offset?'Main':'Prev';
        if (spaceState===0) {
            return <img src={spaceimg0} className='space' id={id} alt="a final frontier" />;
        } else if (spaceState===1) {
            return <img src={spaceimg1} className='space' id={id} alt="a final frontier" />;
        }
        else if (spaceState===2) {
            return <img src={spaceimg2} className='space' id={id} alt="a final frontier" />;
        }
        else if (spaceState===3) {
            return <img src={spaceimg3} className='space' id={id} alt="a final frontier" />;
        }
        else if (spaceState===4) {
            return <img src={spaceimg4} className='space' id={id} alt="a final frontier" />;
        }
        else if (spaceState===5) {
            return <img src={spaceimg5} className='space' id={id} alt="a final frontier" />;
        }
        else if (spaceState===6) {
            return <img src={spaceimg6} className='space' id={id} alt="a final frontier" />;
        }
        else if (spaceState===7) {
            return <img src={spaceimg7} className='space' id={id} alt="a final frontier" />;
        }
        else if (spaceState===8) {
            return <img src={spaceimg8} className='space' id={id} alt="a final frontier" />;
        }
        else if (spaceState===9) {
            return <img src={spaceimg9} className='space' id={id} alt="a final frontier" />;
        }
        else if (spaceState===10) {
            return <img src={spaceimg10} className='space' id={id} alt="a final frontier" />;
        }
        else if (spaceState===11) {
            return <img src={spaceimg11} className='space' id={id} alt="a final frontier" />;
        }
        else if (spaceState===12) {
            return <img src={spaceimg12} className='space' id={id} alt="a final frontier" />;
        }
        else if (spaceState===13) {
            return <img src={spaceimg13} className='space' id={id} alt="a final frontier" />;
        }
        else if (spaceState===14) {
            return <img src={spaceimg14} className='space' id={id} alt="a final frontier" />;
        }
        else if (spaceState===15) {
            return <img src={spaceimg15} className='space' id={id} alt="a final frontier" />;
        }
        else if (spaceState===16) {
            return <img src={spaceimg16} className='space' id={id} alt="a final frontier" />;
        }
        else if (spaceState===17) {
            return <img src={spaceimg17} className='space' id={id} alt="a final frontier" />;
        }
        else if (spaceState===18) {
            return <img src={spaceimg18} className='space' id={id} alt="a final frontier" />;
        }
    }

    return (
        // <Timeline target={<div id="starfield" className="starfield"></div>}>
            <Tween from={{ opacity: 0 }} duration={5} >
                <div id="starfield" className="starfield">
                    <Timeline ref={tweenSpaceMain} target={getSpaceSrc(1)}>
                        <Tween from={{ scale:1.15, opacity:1 }} to={{ scale:1.2, opacity: 0, ease:'none', duration: 1/3*period/1000 }} position="0">
                            {getSpaceSrc(0)}
                        </Tween>
                        <Tween from={{ opacity:0 }} to={{ opacity: 1, ease:'none', duration: 1/3*period/1000 }} position="0" />
                        <Tween from={{ scale:1 }} to={{ scale: 1.15, ease:'none', duration: period/1000 }} position="0" />
                    </Timeline>

                    <Timeline ref={tweenStarsMain} target={getStarSrc(1)}>
                        <Tween from={{ scale:1.45, opacity:1 }} to={{ scale:1.6, opacity: 0, ease:'none', duration: 1/3*period/1000 }} position="0">
                            {getStarSrc(0)}
                        </Tween>
                        <Tween from={{ opacity:0 }} to={{ opacity: 1, ease:'none', duration: 1/3*period/1000 }} position="0" />
                        <Tween from={{ scale:1 }} to={{ scale: 1.45, ease:'none', duration: period/1000 }} position="0" />
                    </Timeline>
                    
                    
                </div>        
            </Tween>
        // </Timeline>
    )
}