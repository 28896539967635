import { useEffect, useState, useRef } from 'react';
import Star from './Star';

// // todo. list should come from config/settings
// const list = [];
// for (var i=0;i<66;i++){
//     list.push( { x:-50 + Math.random()*100, y:-50 + Math.random()*100, i:i } );
// }

// [1,"1_A_Elaine_12_21bpm_44100_24.mp3",-4.22,4.33,-10.88,10.88]

export default function MainApp( { list, counter, delta, socket } ) {

    const [ activeList, _setActiveList ] = useState( {} );
    const [ visibleNum, setVisibleNum ] = useState(0);

    const activeListRef = useRef(activeList);
    const setActiveList = data => {
        activeListRef.current = data;
        _setActiveList(data);
    }

    useEffect( ()=>{
        
        // console.log( 'helloo',window.navigator.getAutoplayPolicy('audiocontext') );
        console.log('init main app', socket);

        if (socket){
            // socket.on('refresh',refreshStars);
            socket.on('activated',onActivated);
            socket.on('deactivated',onDeactivated);

            socket.emit('refresh',(resp)=>{
                console.log('refreshed',resp);
                setActiveList( resp.list );
            }); // request latest set of stars

            let repeater = setInterval( ()=>{ 
                if (visibleNum>list.length) {
                    clearInterval(repeater);
                    console.log('stop!');
                }
                setVisibleNum(v=>v+1) }, 100 );

            return ()=>{
                socket.removeAllListeners();
                clearInterval(repeater);
            }
        }

        
    }, [socket] );

    // useEffect( ()=>{
    //     console.log('list updated',activeList);
    // }, [activeList])

    function onActivated( obj ) {
        console.log('activated', obj);
        // item.textContent = 'star activated : ' + obj.id + ', ' + obj.activator + ', ' + obj.startTime;
        setActiveList( { ...activeListRef.current, [obj.id]:obj } );
    }
    function onDeactivated( obj ) {
        console.log('deactivated',obj);
        // item.textContent = 'star deactivated : ' + obj.id;
        const newlist = { ...activeListRef.current };
        delete newlist[obj.id];
        setActiveList( newlist );
    }

    function onClick( id ){
        // console.log('click',id,activeListRef.current[id]);
        // is id in list?
        if (activeListRef.current[id]){
            socket.emit('deactivate', {id:id});
        } else {
            const now = (new Date()).getTime();
            socket.emit('activate', {id:id,counter:counter,time:now+delta});
        }
    }

    return (
        <div className="soundfield">
            { list.map( s=> <Star x={s[2]} y={s[3]} z={s[4]} i={s[0]} tempo={s[6]} key={s[0]} src={s[1]} active={activeList[s[0]]} delta={delta} onClick={onClick} isVisible={s[0]<visibleNum} /> )}
        </div>

        // <div>
        //     { list.map( s => {
        //         if (s[0]<3) return <audio autoPlay preload="auto" loop><source src={'/audio/'+s[1]} type="audio/mpeg" /></audio>
        //     } ) }
        // </div>
    )
}